import { useUserSocket, useMarqueeSocket } from '@hooks/socket';

// 使用示例
// const socketManager = new SocketManager();
// socketManager.switchSocket(true); // 切换到用户 socket
// socketManager.switchSocket(false); // 切换到跑马灯 socket
// socketManager.closeSocket(); // 关闭所有 socket
export class SocketManager {
  constructor() {
    this.socketUser = null;
    this.socketMarquee = null;
  }

  closeSocket() {
    if (this.socketMarquee) {
      this.socketMarquee.close();
      this.socketMarquee = null;
    }
    if (this.socketUser) {
      this.socketUser.close();
      this.socketUser = null;
    }
  }

  switchSocket(userFlag) {
    if (userFlag) {
      if (this.socketMarquee) {
        this.socketMarquee.close();
        this.socketMarquee = null;
      }
      if (!this.socketUser) {
        this.socketUser = useUserSocket();
      }
    } else {
      if (this.socketUser) {
        this.socketUser.close();
        this.socketUser = null;
      }
      if (!this.socketMarquee) {
        this.socketMarquee = useMarqueeSocket();
      }
    }
  }
}
