import request from '@request';
/**
 * 绑定充值优惠码
 */
export function BindDiscountCode(discountCode) {
  return request.$post('/activity/api/discountCode/bind', discountCode);
}
/**
 * 获取充值码
 */
export function GetRechargeClabe(obj) {
  return request.$post('/pay/api/pay/recharge/create', obj);
}

/** 创建提现单 */
export function CreateWithdrawal(obj) {
  return request.$post('/pay/api/pay/withdrawal/create', { ...obj });
}

/**
 * 查询银行列表
 */
export function QueryBankList() {
  return request.$get('/pay/front/bank/query');
}

/**
 * 查询充值、提现流水
 * @param {*} obj
 */
export function QueryPayRecord(obj) {
  return request.$post('/pay/front/flow/query', { ...obj });
}

/**
 * 查询是否充过值
 */
export function CheckUserIsRecharge(obj) {
  return request.$post('/user/api/userinfo/checkUserIsPay', obj);
}

/**
 * 是否是第一次提现
 */
export function IsFirstWithdrawal() {
  return request.$post('/pay/api/pay/withdrawal/firstCheck');
}

/**
 * 充值活动规则信息查询
 */
export function QueryRechargeActivity() {
  return request.$post('/activity/api/queryRechargeActivity');
}

/**
 * 查询充值页面配置
 */
export function QueryRechargeOptions() {
  return request.$get('/config/api/front/rechargePage/get');
}

/**
 * 查询充值的通知开关
 */
export function QueryRechargeNotice() {
  return request.$get('/config/api/front/trade/tradeShowConfig');
}

/**
 * 获取提现比例金额
 */
export function queryWithdrawalTax() {
  return request.$get('/pay/front/queryWithdrawalTax');
}

/**
 * 获取充值渠道
 */
export function queryChannelList() {
  return request.$post('/pay/front/pay/enabledChannel');
}

/**
 * 更新交易所
 */
export function updateBourse(data) {
  return request.$post('/coins/api/coins/exchange/update', data);
}

/**
 * 创建发票链接
 */
export function createTGPay(data) {
  return request.$post('/pay/api/pay/tg/star/create', data);
}

/**
 * 支付列表
 */
export function getPayList(data) {
  return request.$post('/pay/front/pay/channel/tg', data);
}
