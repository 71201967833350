/*
 * @Author       : LQ
 * @Description  :
 * @version      : 1.0
 * @Date         : 2021-08-20 16:44:21
 * @LastAuthor   : LQ
 * @lastTime     : 2021-08-20 17:20:14
 * @FilePath     : /u-view2.0/uview-ui/libs/config/props/skeleton.js
 */
export default {
    // skeleton
    skeleton: {
        loading: true,
        animate: true,
        rows: 0,
        rowsWidth: '100%',
        rowsHeight: 18,
        title: true,
        titleWidth: '50%',
        titleHeight: 18,
        avatar: false,
        avatarSize: 32,
        avatarShape: 'circle'
    }
}
