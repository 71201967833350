import * as common from './common.api'
import * as login from './login.api'
import * as game from './game.api'
import * as user from './user.api'
import * as buy from './buy.api'
import * as activity from './activity.api'

const COMMONAPI = { ...common }
const LOGINAPI = { ...login }
const GAMEAPI = { ...game }
const USERAPI = { ...user }
const BUYAPI = { ...buy }
const ACTIVITYAPI = { ...activity }

export { COMMONAPI, LOGINAPI, GAMEAPI, USERAPI, BUYAPI, ACTIVITYAPI }
