import request from '@request';

/**
 * 获取游戏全部分类
 */
export function GetGameCategory() {
  return request.$get('/games/api/front/queryGamesCategory');
}

/**
 * 获取游戏子分类，是单独的，不属于一二级分类，是固定的
 * 请求参数：{"subCategoryIds":[]}
 * subCategoryIds--子类ID数组
 */
export function GetGameSubCategory() {
  return request.$post('/games/api/front/queryGamesSubCate');
}

/**
 * 查询游戏信息
 * @param {*} queryObj
 * 子类信息 subCategoryIds:[]
 * 游戏提供者品牌ID: brandId: Long
 */
export function QueryGameDetail(queryObj) {
  return request.$post('/games/api/front/queryGames', { ...queryObj });
}

/**
 * 查询子分类下的游戏
 * @param {*} obj
 */
export function GetSubCateGames(obj) {
  return request.$post('/games/api/front/querySubCateGames', { ...obj });
}

/**
 * 获取游戏url,需要登录
 */
export function GetGameReqInfo({ timestamp, lang, charset, version, data }) {
  return request.$post('/games/api/user/front/getRequestInfo', {
    timestamp,
    lang,
    charset,
    version,
    data,
  });
}
/**
 * 获取游戏url,不需要登录
 */
export function GetGameReqInfoNoLogin({ timestamp, lang, charset, version, data }) {
  return request.$post('/games/api/front/getRequestInfo', {
    timestamp,
    lang,
    charset,
    version,
    data,
  });
}

/**
 * 获取pg游戏url
 */
export function launchPgSoftGame(params) {
  return request.$get(`/games/api/user/front/launchPgSoftGame/${params.externalGameId}?gameId=${params.gameId}`);
}

/**
 * 获取游戏流水
 */
export function GetGameFlow(params) {
  return request.$post('/games/api/user/front/queryGameFlow', params);
}

/**
 * 获取充值送指定游戏彩金的活动数据
 */
export function GetGameRechargeData() {
  return request.$post('/activity/api/front/pushActivity/query');
}

/**
 * 领取活动送的游戏彩金
 */
export function JoinAndGold(ruleId) {
  return request.$post('/activity/api/pushActivity/join', { ruleId });
}

/**
 * 获取彩金可以玩的游戏
 * 请求参数：id --活动ID
 */
export function QueryBoundsGame(obj) {
  return request.$post('/activity/api/front/queryActivityEnroll', obj);
}

/**
 * 获取tg金币的游戏
 */
export function QueryTGGame() {
  return request.$post('/coins/api/coins/account/games');
}
