import { useCommonStore } from '@stores/common';
import config from '@config';
import { setLocalData, getLocalData, delLocalData, dateFormat, shuffleArray, getSocketPath } from '@util';

class WebSocketClient {
  constructor(opts) {
    this.url = opts.url;
    this.socket = null;
    this.timer = null;
    this.outTimer = null;
    this.reconnectInterval = 10000; // 重连间隔时间，单位：毫秒
    this.maxReconnectAttempts = 20; // 最大重连尝试次数
    this.reconnectAttempts = 0; // 当前重连尝试次数
    this.heartbeatInterval = 20000; // 心跳检测间隔时间，单位：毫秒
    this.isOpen = false;

    this.connect(opts.onMessage);

    return this;
  }

  connect(messageCallback) {
    // #ifdef H5
    this.socket = new WebSocket(this.url);

    this.socket.onopen = () => {
      // console.log('WebSocket connection opened')
      this.reconnectAttempts = 0;
      this.setupHeartbeat();
    };

    this.socket.onmessage = messageCallback;

    this.socket.onclose = (event) => {
      if (event.code === 1000 || event.code === 1001 || event.code === 1005 || event.code === 1006) {
        if (this.timer) clearInterval(this.timer);
        if (this.outTimer) clearTimeout(this.outTimer);
        // console.log('WebSocket connection closed cleanly')
      } else {
        // console.log(
        //   "WebSocket connection closed with error:",
        //   event.code,
        //   event.reason
        // );
        this.reconnect();
      }
    };

    this.socket.onerror = (error) => {
      // console.log("WebSocket error:", error);
      this.reconnect();
    };
    // #endif

    // #ifdef APP-PLUS
    this.socket = uni.connectSocket({
      url: this.url,
      complete: () => {},
    });

    // uni.onSocketOpen(() => {
    this.socket.onOpen(() => {
      // console.log('=============onSocketOpen connection opened')
      this.isOpen = true;
      this.reconnectAttempts = 0;
      this.setupHeartbeat();
    });

    // uni.onSocketMessage(messageCallback);
    this.socket.onMessage(messageCallback);

    // uni.onSocketClose((event) => {
    this.socket.onClose((event) => {
      if (event.code === 1000 || event.code === 1001 || event.code === 1005 || event.code === 1006) {
        if (this.timer) clearInterval(this.timer);
        if (this.outTimer) clearTimeout(this.outTimer);
        // console.log('WebSocket connection closed cleanly')
      } else {
        // console.log(
        //   "WebSocket connection closed with error:",
        //   event.code,
        //   event.reason
        // );
        this.reconnect();
      }
      this.isOpen = false;
    });

    // uni.onSocketError(function (res) {
    this.socket.onError(function (res) {
      this.isOpen = false;
      // console.log("WebSocket error:", error);
      this.reconnect();
    });
    // #endif
  }

  reconnect() {
    if (this.timer) clearInterval(this.timer);
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      this.outTimer = setTimeout(() => {
        // console.log('Attempting to reconnect (attempt ' + this.reconnectAttempts + ')')
        this.connect();
      }, this.reconnectInterval);
    } else {
      console.log('Max reconnect attempts reached. Could not reconnect.');
    }
  }

  setupHeartbeat() {
    // console.log('---setupHeartbeat', this.timer)
    // 定时发送心跳消息
    if (this.timer) clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.send('HEART_BEAT'); // 发送心跳消息
    }, this.heartbeatInterval);
  }

  send(message) {
    // #ifdef H5
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.log('WebSocket not connected. Message not sent:', message);
    }
    // #endif

    // #ifdef APP-PLUS
    if (this.socket && this.isOpen) {
      uni.sendSocketMessage({
        data: message,
      });
    } else {
      console.log('WebSocket not connected. Message not sent:', message);
    }
    //  #endif
  }

  close() {
    // debugger
    if (this.socket) {
      if (this.timer) clearInterval(this.timer);
      // #ifdef H5
      this.socket.close();
      //  #endif
      // #ifdef APP-PLUS
      // 注意这里有时序问题，
      // 如果 uni.connectSocket 还没回调 uni.onSocketOpen，而先调用 uni.closeSocket，那么就做不到关闭 WebSocket 的目的。
      // 必须在 WebSocket 打开期间调用 uni.closeSocket 才能关闭。
      // uni.onSocketOpen(function () {
      this.socket.onOpen(function () {
        uni.closeSocket();
      });
      //  #endif
    }
  }
}

function createSocketConnect(opts) {
  return new WebSocketClient(opts);
}

/**
 * 用户登录后的socket
 */
export function useUserSocket() {
  const store = useCommonStore();
  if (!store.isLogin) return null;
  // 'wss://mx.igming.com/proxy-api/config/api/front/push/auth/Jdtf005'
  let socketUrl = `${getSocketPath(store.baseUserInfo.userName)}`;
  return createSocketConnect({
    url: socketUrl,
    onMessage: function (e) {
      // console.log('-----user socket接收到消息:', e.data);
      const msg = JSON.parse(e.data);

      if (!msg) return false;

      if (msg.isStation) {
        store.operateUnReadCount(); // 递增用户未读消息数量
        // if (store.baseUserInfo.stationUnReadCount > 0) {
        // uni.showTabBarRedDot({ index: 3 });
        // }
      }

      // type: 1为系统消息；2为活动
      let { code, type, content } = msg;

      store.setUseUserSocket(code); // useUserSocket已经实例化

      // if (content) { // 设置消息体里 自定义的code
      //   let parseContent = JSON.parse(content);
      //   console.log('content.code', parseContent)
      //   parseContent?.code && store.setUseUserSocketContentCode(parseContent.code)
      // }
      if (type == 1) {
        switch (code) {
          case 'MARQUEE': // 跑马灯
            let mmsg = JSON.parse(content);
            // console.log('-----marquee-:', mmsg);
            store.setMarqueeList(shuffleArray(mmsg));
            // TODO: 以下打开可测试，上线需注释
            // store.setIndexDialogUserMap(1, JSON.parse(content)); // 登录
            // store.setIndexDialogSysMap(1, JSON.parse(content)); // 未登录
            break;
          case 'USER_FIRST_WITHDRAWAL_NOTICE':
            // 游戏内弹窗提示达到可提现金额提示
            setTimeout(() => {
              store.setIsShowPickUpDialog(true, content);
            }, 3000);
            break;
          case 'INDEX_DIALOG_OPEN_MESSAGE': // 首页弹窗开启信息
            // content.code === 'LOGIN_INVITE_MODAL'  // 首页是否充值过弹窗【邀请好友、代理需求 20240407】
            // content.code === 'LOGIN_SIGNIN_MODAL'  // 首页当天是否签到弹窗【每天弹一次 20240624】
            // content.code === 'LOGIN_ACTIVITYLIST_MODAL'  // 首页活动列表弹窗【20240624】
            // console.log('+++socket-user---INDEX_DIALOG_OPEN_MESSAGE');
            // APP_REGISTER_ACTIVITY_NOTICE // app登录奖励弹窗
            store.setIndexDialogUserMap(1, JSON.parse(content));
            break;
          case 'INDEX_DIALOG_CLOSE_MESSAGE': // 删除首页弹窗本地信息
            content = JSON.parse(content);
            delLocalData(`isShowIndexDialog-${store.baseUserInfo.userName}-${content.id}`);
            // console.log('+++socket-user---INDEX_DIALOG_CLOSE_MESSAGE');
            store.setIndexDialogUserMap(0, content);
            break;
          case 'RECHARGE_NOTICE': // 充值通知
            store.setIsRecharged();
            store.setIsNeedGetUserInfo(1);

            // 统计代码，首次充值并
            /*BUYAPI.IsFirstWithdrawal().then((res) => {
               if (
                res.isFirstWithdrawal &&
                (route.query?.s == 3 || route.query?.s == 4)
              ) {
                console.log('---统计-充值')
                fbq && fbq('track', 'Purchase', { currency: 'MXN', value: 100 })
              } 
            })*/
            break;
          case 'WINNINGS_RECHARGE': // 彩金充值到账
            store.setIsNeedGetUserInfo(1);
            break;
          case 'RECHARGE_GUIDE_NOTICE': // 新注册用户7天内弹引导充值弹窗
            // const today = dateFormat(new Date());
            // const isShowToday = getLocalData(`${store.baseUserInfo.userName}-topup`) || 0;
            // if (!(isShowToday && isShowToday == today)) {
            //   setLocalData(`${store.baseUserInfo.userName}-topup`, today);
            // setTimeout(() => {
            store.switchIsShowNewUserTopUpDialog(true);
            // }, 5000);
            // }
            break;
          case 'APP_REGISTER_ACTIVITY_NOTICE': // APP登录奖励弹窗（送$20）
            // {"code":"APP_REGISTER_ACTIVITY_NOTICE","content":"{\"userName\":\"CO675962\",\"amount\":20.0,\"ruleId\":59,\"platform\":\"co1\"}","isStation":false,"title":"","type":1,"userName":"CO675962"}
            store.setIsShowAppLoginDialog(true);
            break;
          case 'SPORT_GAME_BET_SUCCESS': // 体育投注成功
            store.setSportsBetSuccess(Math.random());
            break;
          case 'ACTIVITY_BET_FLOW_FINISH_NOTICE':
            // // 延时1天显示首页彩金step1的进度条
            // let dateTime = Date.now() + 1000 * 60 * 60 * 24;
            // setLocalData(`${store.baseUserInfo.userName}-setp1`, dateTime);
            break;
          case 'PROMOTE_DATA_CHANGE_NOTICE': // 推广数据变更
            store.setReceiveInvitePushMsg(true);
            console.log('----------------->>> receive invite push msg');
            break;
          case 'NEWBIE_GIFT_JOIN_NOTICE': // 新手大礼包
            let data = JSON.parse(content);
            console.log('socket---data: ', data);
            store.setNewUserRouletteDia(true, data);
            break;
          default:
            break;
        }
      }

      if (type == 2) {
        switch (code) {
          case 'REGISTER_NOTICE': // 注册送彩金活动弹窗，需要弹窗提示
            // console.log("-------------注册送彩金--", content);
            // const today = dateFormat(new Date());
            // const isShowToday = getLocalData(store.baseUserInfo.userName) || 0;

            // if (!(isShowToday && isShowToday == today)) {
            //   setLocalData(store.baseUserInfo.userName, today);
            // TODO:一直弹
            store.switchIsShowRegisterDialog(true);
            // }
            break;
          default:
            break;
        }
      }
    },
  });
}

/**
 * 接收跑马灯数据
 * @param {*} messageCallback
 */
export function useMarqueeSocket() {
  const store = useCommonStore();
  return createSocketConnect({
    url: `${getSocketPath()}`,
    onMessage: function (e) {
      const msg = e.data && JSON.parse(e.data);
      // console.log('-----system socket接收到消息:', msg);

      if (!msg) return false;
      // type: 1为系统消息；2为活动
      const { code, type, content } = msg;
      // console.log("----------------------跑马灯类型:", type, code, msg);
      if (type == 1) {
        switch (code) {
          case 'MARQUEE': // 跑马灯
            let mmsg = JSON.parse(content);
            // console.log('---sys--marquee-:', mmsg);
            store.setMarqueeList(shuffleArray(mmsg));
            // TODO: 以下打开可测试，上线需注释
            // store.setIndexDialogUserMap(1, JSON.parse(content)); // 登录
            // store.setIndexDialogSysMap(1, JSON.parse(content)); // 未登录
            break;
          case 'INDEX_DIALOG_OPEN_MESSAGE': // 首页弹窗开启信息
            store.setIndexDialogSysMap(1, JSON.parse(content));
            break;
          case 'INDEX_DIALOG_CLOSE_MESSAGE': // 首页弹窗关闭信息
            let imsg = JSON.parse(content);
            delLocalData(`isShowIndexDialog-${imsg.id}`);
            store.setIndexDialogSysMap(0, imsg);
            break;
          default:
            break;
        }
      }
    },
  });
}
