import request from '@request'

/**
 * 登录
 * @param {*} param0
 * @returns
 */
export function Login(params) {
  return request.$post('/auth/user/login', params)
}

/**
 * 检查用户名是否存在
 * @param {*} username
 * @param {*} tenant
 * @returns
 */
// export function checkUsername({ username }) {
//   return request.$get('/user/userinfo/queryUser', {
//     username
//   })
// }

/**
 * 注册
 * @param {*} param0 { username, password, email, mobile, proxyCode, source,fbc,camp,groups,ad }
 */
export function Register(params) {
  return request.$post('/auth/user/register', params)
}
/**
 * 重置密码
 * @param {*} param0
 */
export function resetPwd({ mobile, password, smsCode, userName }) {
  return request.$post('/user/api/front/user/updatePwd', {
    mobile,
    password,
    smsCode,
    userName
  })
}

/**
 * 修改密码
 * @param {*} param0 {  password }
 */
export function changePwd(params) {
  return request.$post('/user/api/userinfo/chPwdOnline', params)
}

/**
 * 注册按钮提示文本
 * @param {*} params 
 * @returns 
 */
export function queryRegisterTipText(params) {
  return request.$get('/activity/api/front/queryRegisterTipText', params)
}