/*
 * @Author       : LQ
 * @Description  :
 * @version      : 1.0
 * @Date         : 2021-08-20 16:44:21
 * @LastAuthor   : LQ
 * @lastTime     : 2021-08-20 17:12:20
 * @FilePath     : /u-view2.0/uview-ui/libs/config/props/subsection.js
 */
export default {
    // subsection组件
    subsection: {
        list: [],
        current: 0,
        activeColor: '#3c9cff',
        inactiveColor: '#303133',
        mode: 'button',
        fontSize: 12,
        bold: true,
        bgColor: '#eeeeef',
		keyName: 'name'
    }
}
