/*
 * @Author       : LQ
 * @Description  :
 * @version      : 1.0
 * @Date         : 2021-08-20 16:44:21
 * @LastAuthor   : LQ
 * @lastTime     : 2021-08-23 19:51:50
 * @FilePath     : /u-view2.0/uview-ui/libs/config/props/badge.js
 */
export default {
    // 徽标数组件
    badge: {
        isDot: false,
        value: '',
        show: true,
        max: 999,
        type: 'error',
        showZero: false,
        bgColor: null,
        color: null,
        shape: 'circle',
        numberType: 'overflow',
        offset: () => [],
        inverted: false,
        absolute: false
    }
}
