/*
 * @Author       : LQ
 * @Description  :
 * @version      : 1.0
 * @Date         : 2021-08-20 16:44:21
 * @LastAuthor   : LQ
 * @lastTime     : 2021-08-20 17:23:37
 * @FilePath     : /u-view2.0/uview-ui/libs/config/props/tag.js
 */
export default {
    // tag 组件
    tag: {
        type: 'primary',
        disabled: false,
        size: 'medium',
        shape: 'square',
        text: '',
        bgColor: '',
        color: '',
        borderColor: '',
        closeColor: '#C6C7CB',
        name: '',
        plainFill: false,
        plain: false,
        closable: false,
        show: true,
        icon: ''
    }
}
