import { toast, showLoading, hideLoading, getLocalData } from '@util';
// import { Crisp } from 'crisp-sdk-web'
import { Crisp } from './chat_src/index';
// import { pinia } from '@stores'
import { useCommonStore } from '@stores/common';
import { WEBSITE_ID } from '@config';

export function useChat() {
  function initChat() {
    const store = useCommonStore();

    Crisp.configure(store.serviceInfo.config, {
      autoload: true,
      lockFullview: true,
      // segments: ['segment777'], // 设置分段
    });

    Crisp.setColorTheme('green');

    // 设置分段
    const siteName = store.checkSiteConfigRes?.siteName || getLocalData('siteName')
    Crisp.session.setSegments([siteName]);

    // // Example 1: set a single segment
    // $crisp.push(['set', 'session:segments', [['customer']]]);

    // // Example 2: set multiple segments
    // $crisp.push(['set', 'session:segments', [['help', 'customer']]]);

    // // Example 3: overwrite all segments to new segments
    // $crisp.push(['set', 'session:segments', [['new', 'segments'], true]]);

    // Crisp.setHideOnMobile(true)

    Crisp.chat.onChatInitiated(() => {
      // Executed once the chat was initiated from the user
      // hideLoading()
    });

    Crisp.chat.onChatClosed((e) => {
      // hideLoading()
      // 当chat框关闭的时候，后退

      let isOpened = Crisp.chat.isChatOpened();
      // !isOpened && Crisp.chat.open()

      let isVisibleOpened = Crisp.chat.isVisible();

      // Crisp.session.reset() // 会导致重新加载，绝对不能在这里调用
      // debugger
      // reset也会调用该方法，但open为false
      if (isOpened) {
        uni.navigateBack({
          delta: 1,
        });
      }
    });

    Crisp.chat.onChatOpened(() => {
      // 当chat框被打开的时候
      hideLoading();
      // 客服端发送
      let showText = 'Can I help?'
      if (getLocalData('lang') === 'pt_BR') {
        showText = 'Posso ajudar?'
      } else if (getLocalData('lang') === 'es_CO') {
        showText = '¿Puedo ayudarle?'
      }
      Crisp.message.showText(showText);
    });

    Crisp.message.onMessageSent((data) => {
      // 用户发送消息的时候触发
    });

    Crisp.message.onMessageReceived((data) => {
      // 用户接收到消息的时候触发
    });

    /*   let timer = setInterval(() => {
        if (window.$crisp.do) {
          clearInterval(timer);
          showChatView();
        }
      }, 1000); */
  }
  function showChatView() {
    if (window.$crisp.do) {
      let isOpened = Crisp.chat.isChatOpened();
      !isOpened && Crisp.chat.open();

      let isVisibleOpened = Crisp.chat.isVisible();
      !isVisibleOpened && Crisp.chat.show();
      // hideLoading()
    } else {
    }
  }

  function setChatSession() {
    const store = useCommonStore();
    // 设置chat
    if (store.baseUserInfo.userName) {
      Crisp.setTokenId(store.baseUserInfo.userName);
      Crisp.user.setNickname(store.baseUserInfo.userName);
    }

    store.baseUserInfo.email && Crisp.user.setEmail(store.baseUserInfo.email);
    store.baseUserInfo.mobile && Crisp.user.setPhone(store.baseUserInfo.mobile);
  }

  function resetChatSession() {
    Crisp.session.reset();
    Crisp.setColorTheme('green');
  }

  return {
    Crisp,
    initChat,
    setChatSession,
    resetChatSession,
    showChatView,
  };
}
