// 为了让用户能够自定义主题，会逐步弃用此文件，各颜色通过css提供
// 为了给某些特殊场景使用和向后兼容，无需删除此文件(2020-06-20)
const color = {
    primary: '#3c9cff',
    info: '#909399',
    default: '#909399',
    warning: '#f9ae3d',
    error: '#f56c6c',
    success: '#5ac725',
    mainColor: '#303133',
    contentColor: '#606266',
    tipsColor: '#909399',
    lightColor: '#c0c4cc',
    borderColor: '#e4e7ed'
}

export default color
