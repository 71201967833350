import { defineStore } from "pinia";
import { ref, reactive } from "vue";
import {
  getToken,
  cleanToken,
  getLocalData,
  setLocalData,
  delLocalData,
} from "@util";

export const useRouteStore = defineStore(
  "routeStore",
  () => {
    // TODO:控制保存到localStorage还是sessionStroage里，token，等，记住用户登录的用户名、密码
    let isLogin = ref(false); //ref(getToken() ? true : false); // TODO: 登录状态
    let needAutoBackUrl = ref(''); // 需要返回的上个页面路由
    let needAutoBackNum = ref(1); // 需要返回上个页面路由的页面数量 uni.navigateBack
    let searchPrvPage = ref(''); // 搜索需要返回的上个页面路由

    function setNeedAutoBack(url) {
      needAutoBackUrl.value = url;
      setLocalData('needAutoBackUrl', needAutoBackUrl.value)
    }
    // 记录跳转到搜索页面的router
    function setSearchPrvPage() {
      let pages = getCurrentPages();
      let $page = pages[pages.length - 1];
      let $pageFullPath = $page && $page.$page && $page.$page.fullPath; // uniapp当前页面路径
      setLocalData('searchPrvPage', $pageFullPath)
    }

    return {
      needAutoBackUrl,
      setNeedAutoBack,
      searchPrvPage,
      setSearchPrvPage
    }
  },
  {
    persist: {
      enabled: true,
    },
  }
);
