import { setLocalData, delLocalData, firebaseMessage, checkBrowser } from '@util';
import { COMMONAPI, GAMEAPI } from '@api';
import { useCommonStore } from '@stores/common';
import { ref, getCurrentInstance } from 'vue';
import { default as config, handleChannel, languageEnum } from '@config';
import { useUserSocket, useMarqueeSocket } from '@hooks/socket';
import PageConfig from '@/pages.json';
// import { $t } from './language';

function getGameCategoryFun(siteConfigRes, proxy, resolve, reject) {
  try {
    const store = useCommonStore();
    const { setCategoryData } = store;
    // 获取分类
    return COMMONAPI.queryNavigator({}).then((tabRes) => {
      GAMEAPI.GetGameCategory().then((cateRes) => {
        const mergedArray = tabRes.map((item1) => {
          item1 = {
            ...item1,
            cateName: item1.name,
            tabIndex: item1.name,
            tabPath: item1.path,
            catePath: '',
            visible: true,
          };
          const matchedItem2 = cateRes.find((item2) => item2.code === item1.cateCode);
          if (matchedItem2) {
            return {
              ...matchedItem2,
              ...item1,
              catePath: matchedItem2.path, // 分类接口返回的path
            };
          }
          return item1;
        });
        const defaultCode = PageConfig.pages[0].style.code;
        const defaultExpand = mergedArray.filter((item) => item.code === defaultCode || item.cateCode === defaultCode);
        const configExpand = mergedArray.filter((item) => item.expand === true);
        setCategoryData('clean');
        setCategoryData(mergedArray, configExpand[0] || defaultExpand[0]);
        resolve({
          siteConfigRes,
          queryGamesCategoryRes: mergedArray,
        });
      });
    });
  } catch (error) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
}

function getServiceInfoFun() {
  COMMONAPI.GetServiceInfo().then((res) => {
    const store = useCommonStore();
    store.setServiceInfo(res);
    setLocalData('serviceInfo', res);
  });
}

export function appLaunchFun(proxy) {
  return new Promise((resolve, reject) => {
    const store = useCommonStore();
    const { setCheckSiteConfig, setCheckSiteConfigRes } = store;
    const { proxy, appContext } = getCurrentInstance();
    const global = appContext.config.globalProperties;
    console.log('---start---checkSiteConfig');
    COMMONAPI.GetBaseInfoByUrl()
      .then(async (res) => {
        console.log('---response---checkSiteConfig');

        // TODO: 上线前需要注释掉
        // res.country = 'NG';

        let lang = languageEnum[res.country];
        if (res.country == 'NG') {
          lang = store.nowLang;
        }
        console.log('---设置语言： ', lang);
        uni.setLocale(lang);
        global.$i18n.global.locale = lang;

        // // 重置语言
        // let phoneLen = $t('common.phoneNumber');
        // phoneLen = 'phone number: ' + phoneLen;
        // console.log('reset-lang: ', phoneLen);

        // #ifdef H5
        document.title = res?.siteName;
        // #endif
        setCheckSiteConfig(true);
        setCheckSiteConfigRes(res);
        for (let key in res) {
          setLocalData(key, res[key]);
        }

        await getGameCategoryFun(res, proxy, resolve, reject);

        try {
          getServiceInfoFun();
          firebaseMessage();
        } catch (error) {}
      })
      .catch((err) => {
        console.log('---catch-response---checkSiteConfig');
        setCheckSiteConfig(false);
        let res = {
          nationCode: '',
          nationName: '',
          areaCode: '',
          currency: '',
          lang: '',
          siteName: '',
          keyWords: '',
          description: null,
          siteLogo: '',
        };
        for (let key in res) {
          setLocalData(key, '');
        }
        reject(err);
      });
  });
}
