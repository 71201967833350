import Report from './report.js'

import { set_page_residence_time, get_time } from './pageTime'
import {
  get_page_types,
  get_platform_name,
  get_route,
  is_push_clientid
  // is_debug,
} from './pageInfo'

import { dbSet } from './db'
class Stat extends Report {
  static getInstance () {
    // if (!uni.__stat_instance) {
    //   uni.__stat_instance = new Stat()
    // }
    // const __stat_instance = new Stat()
    const __stat_instance = new Stat()

    return __stat_instance
  }
  constructor() {
    super()
  }

  /**
   * 获取推送id
   */
  public pushEvent (options) {
    const ClientID = is_push_clientid()
    if (uni.getPushClientId && ClientID) {
      uni.getPushClientId({
        success: (res) => {
          const cid = res.cid || false
          //  只有获取到才会上传
          if (cid) {
            this.sendPushRequest(options, cid)
          }
        },
      })
    }
  }

  /**
   * 进入应用
   * @param {Object} options 页面参数
   * @param {Object} self	当前页面实例
   */
  public launch (options, self) {
    // 初始化页面停留时间  start
    // let residence_time = set_page_residence_time()
    set_page_residence_time()
    this.__licationShow = true
    dbSet('__launch_options', options)
    // 应用初始上报参数为1
    options.cst = 1
    this.sendReportRequest(options, true)
  }
  public load (options, self) {
    this.self = self
    this._query = options
  }

  /**
   * IOS中飞书app不生效（打开后点击左上角关闭或者返回不生效，放到后台是生效的）
   * @param self 
   */
  public appHide (self?: any) {
    this.applicationHide(self, true)
  }

  public appShow (self?: any) {
    this.applicationShow(self)
  }

  public show (self) {
    this.self = self
    if (get_page_types(self) === 'page') {
      this.pageShow(self)
    }

    // #ifdef VUE3
    if (get_platform_name() === 'h5' || get_platform_name() === 'n') {
      if (get_page_types(self) === 'app') {
        this.appShow()
      }
    }
    // #endif

    // #ifndef VUE3
    if (get_page_types(self) === 'app') {
      this.appShow()
    }
    // #endif
  }

  public hide (self) {
    this.self = self
    if (get_page_types(self) === 'page') {
      this.pageHide(self)
    }
    // #ifdef VUE3
    if (get_platform_name() === 'h5' || get_platform_name() === 'n') {
      if (get_page_types(self) === 'app') {
        this.appHide()
      }
      // this.appHide()
    }
    // #endif

    // #ifndef VUE3
    if (get_page_types(self) === 'app') {
      this.appHide()
    }
    // #endif
  }

  public error (em) {
    console.log("===============app-error-stat============", em)

    // 开发工具内不上报错误
    // if (this._platform === 'devtools') {
    //   if (process.env.NODE_ENV === 'development') {
    //     console.info('当前运行环境为开发者工具，不上报数据。')
    //     return
    //   }
    // }
    let emVal = ''
    if (!em.message) {
      // emVal = em
      emVal = JSON.stringify(em)
    } else {
      emVal = em.stack
    }
    if (emVal === '{}') return
    let route = ''
    try {
      route = get_route()
    } catch (e) {
      // 未获取到页面路径
      route = ''
    }

    let options = {
      ak: this.statData.ak,
      uuid: this.statData.uuid,
      p: this.statData.p,
      lt_type: 'stat-error',
      mpsdk: this.statData.mpsdk,
      mpv: this.statData.mpv,
      vc: +this.statData.vc,
      usv: this.statData.usv,
      lt: '31',
      lt_text: 'app-error-stat', // 错误类型
      url: route, // 当前页面路由
      ut: this.statData.ut,// 平台类型
      ch: this.statData.ch,// 渠道
      v: this.statData.v,// 版本号
      em: emVal,// 错误信息
      t: get_time(),// 上报时间
    }
    this.request(options)
  }
}
export default Stat
