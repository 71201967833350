import { createI18n } from 'vue-i18n';
import en from '../../locale/en.json';
import es from '../../locale/es.json';
import co from '../../locale/co.json';
import zh from '../../locale/zh.json';
import br from '../../locale/br.json';
import ng from '../../locale/ng.json';
import ngzh from '../../locale/ngzh.json';
import cl from '../../locale/cl.json';
import pe from '../../locale/pe.json';
import id from '../../locale/id.json';
import ec from '../../locale/ec.json';
import ph from '../../locale/ph.json';

const messages = {
  es,
  co,
  en,
  zh,
  br,
  ng,
  ngzh,
  cl,
  pe,
  id,
  ec,
  ph,
};

// let language = uni.getLocale() || 'es'; // 默认语言
// export const $t = (str) => {
//   Object.keys(messages).forEach(function (key) {
//     if (language.indexOf(key) === 0) {
//       language = key;
//     }
//   });
//   return messages[language][str] ? messages[language][str] : str;
// };

export const $t = (str) => {
  let language = uni.getLocale() || 'es'; // 默认语言
  const i18n = createI18n({
    locale: language,
    messages,
  });

  return i18n.global.t(str);
};
