import { get_platform_name, get_page_vm, is_debug } from './pageInfo'
import type { App } from 'vue'
import Stat from './stat'
import Report from './report'
// import { copy } from '@util'

const stat = Stat.getInstance()
const report = new Report()
// 用于判断是隐藏页面还是卸载页面
let isHide = false

const lifecycle = {
  onLaunch (options) {
    // 进入应用上报数据
    stat.launch(options, this)
    // 上报push推送id
    // stat.pushEvent(options)
  },
  onLoad (options) {
    stat.load(options, this)
    // TODO: 重写分享，获取分享上报事件
    // if (this.$scope && this.$scope.onShareAppMessage) {
    //   let oldShareAppMessage = this.$scope.onShareAppMessage
    //   this.$scope.onShareAppMessage = function (options) {
    //     stat.interceptShare(false)
    //     return oldShareAppMessage.call(this, options)
    //   }
    // }
  },
  onShow () {
    isHide = false
    stat.show(this)
  },
  onHide () {
    isHide = true
    stat.hide(this)
  },
  onUnload () {
    if (isHide) {
      isHide = false
      return
    }
    stat.hide(this)
  },
  onError (e) {
    try {
      console.log("===============应用上报错误-onError============", e)
      // uni.showModal({
      //   title: '-onError-',
      //   content: `${e}\ntoString: ${e.toString()}`,
      //   success: function (res) {
      //     if (res.confirm) {
      //       copy(`${e}\ntoString: ${e.toString()}`)
      //     }
      //   }
      // });
      if (e.response && (e.response.data.code || e.response.data.code === 0) || e.indexOf('statusCode') > -1 || e.errMsg === 'request:fail' || e.statusText === 'request:fail') {
        // 接口请求不上报
      } else {
        stat.error(e)
      }
    } catch (error) {

    }

  }
}

// 加载统计代码

function load_stat (app) {
  app.mixin(lifecycle)
  app.provide('$ganaStat', stat)
}

function main (app: App<Element>) {
  load_stat(app)
  app.config.errorHandler = (err: any, instance: any, info: any) => {
    console.log('-------------------errorHandler', err)
    /**
     * err：错误对象、发生错误的组件实例
     * instance：vue实例
     * info: 是一个 Vue 特定的错误信息  例如：错误是在哪个生命周期的钩子上抛出的
     */
    // 处理错误，例如：报告给一个服务
    console.error(`Error: ${err.toString()}\nInfo: ${info}`)
    // uni.showModal({
    //   title: '-Error-',
    //   content: `${err.toString()}\nInfo: ${info}`,
    //   success: function (res) {
    //     if (res.confirm) {
    //       copy(`${err.toString()}\nInfo: ${info}`)
    //     }
    //   }
    // });
    try {
      if (err.isAxiosError || (err.response && (err.response.data.code || err.response.data.code === 0)) || err.indexOf('statusCode') > -1 || err.errMsg === 'request:fail' || err.statusText === 'request:fail') {
        // 接口请求不上报
      } else {
        report.throw(err)
      }
      report.throw(err)
    } catch (error) { }
  }

  // if (is_debug) {
  //   console.log('=== uni统计开启,version:2.0 ===')
  //   load_stat()
  // } else {
  //   if (process.env.NODE_ENV === 'development') {
  //     uni.report = function (type, options) { }
  //   } else {
  //     load_stat()
  //   }
  // }
}
export function setupGlobalStat (app: App<Element>) {
  main(app)
}
