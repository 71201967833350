/*
 * @Author       : LQ
 * @Description  :
 * @version      : 1.0
 * @Date         : 2021-08-20 16:44:21
 * @LastAuthor   : LQ
 * @lastTime     : 2021-08-20 17:03:27
 * @FilePath     : /u-view2.0/uview-ui/libs/config/props/empty.js
 */
export default {
    // empty组件
    empty: {
        icon: '',
        text: '',
        textColor: '#c0c4cc',
        textSize: 14,
        iconColor: '#c0c4cc',
        iconSize: 90,
        mode: 'data',
        width: 160,
        height: 160,
        show: true,
        marginTop: 0
    }

}
