import { defineStore } from 'pinia';
import { ref, reactive } from 'vue';
import { cleanToken } from '@util';
import Decimal from 'decimal.js';
import { default as config } from '@config';

export const useCommonStore = defineStore(
  `${config._localEnv}common`,
  () => {
    // TODO:控制保存到localStorage还是sessionStroage里，token，等，记住用户登录的用户名、密码
    const isLogin = ref(false); //ref(getToken() ? true : false); // TODO: 登录状态

    function switchLogin(flag, userName) {
      if (!flag) {
        setBaseUserInfo();
        cleanToken();
      }
      isLogin.value = flag;
      baseUserInfo.value.userName = userName ? userName : '';
    }

    let appUpdateType = ref(''); // 安卓app更新类型 0 apk 1是wgt升级
    function setAppUpdateType(type) {
      appUpdateType.value = type;
    }

    let checkSiteConfig = ref(false);
    function setCheckSiteConfig(flag) {
      checkSiteConfig.value = flag;
    }

    let checkSiteConfigRes = ref(null);
    function setCheckSiteConfigRes(data) {
      checkSiteConfigRes.value = data;
    }

    const baseUserInfo = ref({
      userName: '',
      loginUserName: '',
      mobile: '',
      email: '',
      headImg: '',
      totalAmount: 0.0, // 总金额
      withdrawalEnableAmount: 0, // 可提现金额
      principalAmount: 0.0,
      activityAmount: 0.0,
      currentAmount: 0.0,
      realName: '',
      memberLevel: 1,
      levelMinAmt: 0.0,
      levelMaxAmt: 4999.99,
      // authStatus: 0, // 身份认证 0：待认证（需要区分是否上传过信息）；1：认证中；2：认证成功；3：认证失败，待人工审核，4：认证失败
      // id: '', // OCR
      // frontImg: '',
      // backImg: '',
      // handFrontImg: '',
      mobileCheckStatus: false, // 手机验证
      stationUnReadCount: 0, // 未读消息数量
      // tg
      coins: 0, // tg金币余额
      exchangeName: '',
      exchangeLogo: '',
      wallet: '',
    });

    function setBaseUserInfo(userInfo) {
      if (!userInfo) {
        baseUserInfo.value.headImg = '';
        baseUserInfo.value.totalAmount = 0.0;
        baseUserInfo.value.withdrawalEnableAmount = 0;
        baseUserInfo.value.principalAmount = 0.0;
        baseUserInfo.value.activityAmount = 0.0;
        baseUserInfo.value.realName = '';
        baseUserInfo.value.userName = '';
        baseUserInfo.value.loginUserName = '';
        baseUserInfo.value.mobile = '';
        baseUserInfo.value.email = '';
        baseUserInfo.value.memberLevel = 1;
        baseUserInfo.value.levelMinAmt = 0.0;
        baseUserInfo.value.levelMaxAmt = 4999.99;
        baseUserInfo.value.currentAmount = 0;
        baseUserInfo.value.mobileCheckStatus = false;
        baseUserInfo.value.stationUnReadCount = 0;
        baseUserInfo.value.coins = 0;
        return false;
      }

      userInfo?.headImg && (baseUserInfo.value.headImg = userInfo.headImg);
      userInfo?.realName && (baseUserInfo.value.realName = userInfo.realName);
      userInfo?.userName && (baseUserInfo.value.userName = userInfo.userName);
      userInfo?.loginUserName && (baseUserInfo.value.loginUserName = userInfo.loginUserName);
      userInfo?.mobile && (baseUserInfo.value.mobile = userInfo.mobile);
      userInfo?.email && (baseUserInfo.value.email = userInfo.email);
      typeof userInfo.mobileCheckStatus == 'boolean' &&
        (baseUserInfo.value.mobileCheckStatus = userInfo.mobileCheckStatus);
    }

    function setUserAccount(userAccount) {
      baseUserInfo.value.totalAmount = userAccount.accountBalance;
      baseUserInfo.value.principalAmount = Decimal.add(
        parseFloat(userAccount?.principalBalance),
        parseFloat(userAccount?.principalBonusBalance)
      ).toNumber();

      baseUserInfo.value.activityAmount = Decimal.sub(
        parseFloat(baseUserInfo.value.totalAmount),
        parseFloat(baseUserInfo.value.principalAmount)
      );
      baseUserInfo.value.withdrawalEnableAmount = userAccount.withdrawalEnableAmount || 0;
    }

    function setUserMember(memberInfo) {
      memberInfo?.memberLevel && (baseUserInfo.value.memberLevel = memberInfo.memberLevel);
      memberInfo?.levelMinAmt && (baseUserInfo.value.levelMinAmt = memberInfo.levelMinAmt);
      memberInfo?.levelMaxAmt && (baseUserInfo.value.levelMaxAmt = memberInfo.levelMaxAmt);
      memberInfo?.currentAmount >= 0 && (baseUserInfo.value.currentAmount = memberInfo.currentAmount);
    }

    function setUserCoins(coins) {
      baseUserInfo.value.coins = coins;
    }

    function setExchange(data) {
      baseUserInfo.value.exchangeName = data.exchangeName;
      baseUserInfo.value.exchangeLogo = data.exchangeLogo;
    }

    function setWallet(wallet) {
      baseUserInfo.value.wallet = wallet;
    }

    const bannerList = reactive({
      top: [], // 最上方
      left: [], // 左侧边栏
      main: [
        // {img: 'https://backend.365gana.com/prod-api/system/statics/2024/01/17/01VIP_750x400_20240117081516A391.jpg'},
        // {
        //   img: 'https://backend.365gana.com/prod-api/system/statics/2024/01/17/01注册送500欢迎彩金_750x400_首页_20240117020314A998.webp',
        // },
      ], // 主轮播
      second: [], // 次
      middle: [], // 中间
      register: [],
      login: [],
      recharge: [],
      withdraw: [],
      user: [],
    });
    function setBannerList(arr) {
      bannerList.top = [];
      bannerList.left = [];
      bannerList.main = [];
      bannerList.second = [];
      bannerList.middle = [];
      bannerList.register = [];
      bannerList.login = [];
      bannerList.recharge = [];
      bannerList.withdraw = [];
      bannerList.user = [];
      arr.forEach((element) => {
        switch (element.position) {
          case 1:
            bannerList.top.push(element);
            break;
          case 10:
            bannerList.left.push(element);
            break;
          case 2:
            bannerList.main.push(element);
            break;
          case 3:
            bannerList.second.push(element);
            break;
          case 4:
            bannerList.middle.push(element);
            break;
          case 5:
            bannerList.register.push(element);
          case 6:
            bannerList.login.push(element);
            break;
          case 7:
            bannerList.recharge.push(element);
            break;
          case 8:
            bannerList.withdraw.push(element);
            break;
          case 9:
            bannerList.user.push(element);
            break;
          default:
            break;
        }
      });
    }

    function setStationUnReadCount(count) {
      baseUserInfo.value.stationUnReadCount = count;
    }

    // 站内信未读数量操作
    function operateUnReadCount(type, num) {
      !num && (num = 1);
      // 默认是+
      type == undefined
        ? (baseUserInfo.value.stationUnReadCount += 1)
        : baseUserInfo.value.stationUnReadCount > 0 && (baseUserInfo.value.stationUnReadCount -= 1);
    }

    // 当前选中的tabbar
    let selectTabbar = ref(null);

    function setSelectTabbar(data) {
      selectTabbar.value = data;
    }

    // tabbar 首次展开标识
    let tabFirstExpanded = ref(false);
    function setTabFirstExpanded(expanded) {
      tabFirstExpanded.value = expanded;
    }

    // 分类信息
    const categoryData = ref({
      categroyList: [],
      tabbarExpand: null, // 默认展开的分类
      firCategoryId: '',
      // firCategoryIndex: 0,
      // secCategoryIndexArr: {
      //   casino: 1,
      //   bingo: 1,
      //   casinoVivo: 1,
      // }, // 退出游戏的时候需要回显，两个tab，定义数组分别保存
    });

    /**
     * 设置分类数据
     * @param {*} list 分类数据列表
     * @param {*} expand 默认打开的一级分类对象
     */
    function setCategoryData(list, expand) {
      if (list == 'clean') {
        categoryData.value.categroyList = [];
        categoryData.value.tabbarExpand = null;
        categoryData.value.firCategoryId = '';
        // categoryData.value.firCategoryIndex = 0;
        // categoryData.value.secCategoryIndexArr = {
        //   casino: 1,
        //   bingo: 1,
        //   casinoVivo: 1,
        // }; // 只保存casino和casinoVivo的二级分类的index
        return;
      }

      list && (categoryData.value.categroyList = list);
      expand && expand != undefined && (categoryData.value.tabbarExpand = expand);
      expand && expand != undefined && (categoryData.value.firCategoryId = expand?.cateId);
      setSelectTabbar(expand);
      // typeof firIndex == 'string' ? (categoryData.value.firCategoryIndex = firIndex) : '';
      // if (firIndex === 'casino') {
      //   categoryData.value.firCategoryIndex = 0;
      // } else if (firIndex === 'bingo') {
      //   categoryData.value.firCategoryIndex = 1;
      // } else if (firIndex === 'casinoVivo') {
      //   categoryData.value.firCategoryIndex = 4;
      // }

      // if (index >= 0) {
      //   categoryData.value.secCategoryIndexArr[firIndex] = index;
      // }
    }

    // 游戏方块点击后遮罩层显隐控制index
    const squareMarkShowObj = reactive({
      default: -1,
    });

    function setSquareMarkShowObj(key, index) {
      if (index >= 0) {
        for (let key in squareMarkShowObj) {
          if (Object.prototype.hasOwnProperty.call(squareMarkShowObj, key)) {
            squareMarkShowObj[key] = -1;
          }
        }
      }
      squareMarkShowObj[key] = index;
    }

    const squareBigMarkShowObj = reactive({
      default: -1,
    });

    function setSquareBigMarkShowObj(key, index) {
      if (index >= 0) {
        for (let key in squareBigMarkShowObj) {
          if (Object.prototype.hasOwnProperty.call(squareBigMarkShowObj, key)) {
            squareBigMarkShowObj[key] = -1;
          }
        }
      }
      squareBigMarkShowObj[key] = index;
    }

    // 控制chat图标是否显示
    const isShowChatIcon = ref(false);

    function switchShowChatIcon(flag) {
      isShowChatIcon.value = flag;
    }

    // 控制keepalive
    const include = ref([]);

    function setInclude(name) {
      include.value[0] = name;
    }

    // 退出游戏并且是首次提现，顶部显示可提现余额，5s
    // const isShowPickUpTip = ref(false);

    // function setIsShowPickUpTip(flag) {
    //   console.log("[setIsShowPickUpTip]", flag)
    //   isShowPickUpTip.value = flag;
    // }

    // TODO:游戏页面，首次达到可提现金额，显示弹窗
    const isShowPickUpDialog = ref(false);
    const showPickUpAmount = ref(0);

    function setIsShowPickUpDialog(flag, amount) {
      isShowPickUpDialog.value = flag;
      showPickUpAmount.value = amount;
    }
    let useUserSocketCode = ref('');
    function setUseUserSocket(code) {
      useUserSocketCode.value = code;
    }

    let useUserSocketContentCode = ref('');
    function setUseUserSocketContentCode(contentCode) {
      useUserSocketContentCode.value = contentCode;
    }

    // 亡灵节活动
    const isWLJActivty = ref(false);
    const wljObj = ref({});
    function setWLJActivty(flag, obj) {
      isWLJActivty.value = flag;
      wljObj.value = obj;
    }

    // 跑马灯数据
    const marqueeList = ref();
    function setMarqueeList(data) {
      marqueeList.value = data;
    }

    // 注册（后）信息弹窗
    const isShowRegisterUserInfoDialog = ref(false);
    function switchIsShowRegisterUserInfoDialog(flag) {
      isShowRegisterUserInfoDialog.value = flag;
    }

    // 注册送彩金活动弹窗
    const isShowRegisterDialog = ref(false);
    function switchIsShowRegisterDialog(flag) {
      isShowRegisterDialog.value = flag;
    }

    // 新注册用户7天内弹引导充值弹窗
    const isShowNewUserTopUpDialog = ref(false);
    function switchIsShowNewUserTopUpDialog(flag) {
      isShowNewUserTopUpDialog.value = flag;
    }

    // app首次登录奖励弹窗
    const isShowAppLoginDialog = ref(false);
    function setIsShowAppLoginDialog(flag) {
      isShowAppLoginDialog.value = flag;
    }

    // 每日签到弹窗
    const isShowSignInDialog = ref(false);
    function setIsShowSignInDialog(flag) {
      isShowSignInDialog.value = flag;
    }

    // 活动列表弹窗, 不限制次数，除非手动关闭
    const isShowActivityListDialog = ref(false);
    function setIsShowActivityListDialog(flag) {
      isShowActivityListDialog.value = flag;
    }

    // 控制弹窗的总开关，从游戏返回回来的，不显示弹窗
    const isShowIndexDialogTotal = ref(true);
    function switchIsShowIndexDialogTotal(flag) {
      isShowIndexDialogTotal.value = flag;
    }

    // 首页系统弹窗是否显示 system socket
    const indexDialogSysMap = reactive(new Map());
    function setIndexDialogSysMap(type, obj) {
      if (type == 1) {
        indexDialogSysMap.set(obj.id, obj);
      }
      if (type == 0) {
        indexDialogSysMap.delete(obj.id);
      }
    }

    // 首页用户弹窗是否显示 user socket
    const indexDialogUserMap = reactive(new Map());
    function setIndexDialogUserMap(type, obj) {
      if (type == 1) {
        indexDialogUserMap.set(obj.id, obj);
      }
      if (type == 0) {
        indexDialogUserMap.delete(obj.id);
      }
    }

    // 挂机金币弹窗
    const isShowHookCoinsDia = ref(false);
    function setIsShowHookCoinsDia(flag) {
      isShowHookCoinsDia.value = flag;
    }
    // 挂机金币数据
    const hookCoinsData = ref({ hookBalance: 0, hookBalanceList: [] });
    function setHookCoinsData(data) {
      hookCoinsData.value.hookBalance = data.hookBalance || 0;
      hookCoinsData.value.hookBalanceList = data.hookBalanceList;
    }

    const testWatch = ref([]);
    function setTestWatch(type, obj) {
      if (type == 1) {
        testWatch.value.push(obj);
      } else if (type == 0) {
        testWatch.value.unshift();
      }
    }

    // 未充值时点击游戏弹窗提示
    let isShowFirstRechargeDialog = ref(false);
    function switchIsShowFirstRechargeDialog(flag) {
      isShowFirstRechargeDialog.value = flag;
    }

    // 获取用户最新数据
    const isNeedGetUserInfo = ref(false);
    function setIsNeedGetUserInfo(flag) {
      isNeedGetUserInfo.value = flag;
    }

    // 充值页面拦截弹窗
    const isShowRechargeDia = ref(false);
    const rechargeGoToPage = ref('');
    const rechargeGoToPageCb = ref(null);
    function setIsShowRechargeDia(flag, pagePath, cb) {
      isShowRechargeDia.value = flag;
      pagePath != '' ? (rechargeGoToPage.value = pagePath) : '';
      rechargeGoToPageCb.value = cb || null;
    }

    // 是否充值过（首充）
    const isRecharged = ref(false);
    function setIsRecharged() {
      console.log('---冲冲冲112234');
      isRecharged.value = true;
    }

    const isShowRecharge1Dia = ref(false);
    function setIsShowRecharge1Dia(flag) {
      isShowRecharge1Dia.value = flag;
    }

    //客服信息
    const serviceInfo = ref({});
    function setServiceInfo(info) {
      serviceInfo.value = info;
    }

    const registerInfo = reactive({
      username: '',
      mobile: '',
      password: '',
    });
    function setRegisterInfo(info) {
      registerInfo.username = info.username;
      registerInfo.mobile = info.mobile;
      registerInfo.password = info.password;
    }

    // // playgame页面中的iframe页面
    // const isShowIframePage = ref(false);
    // function switchIsShowIframePage(flag) {
    //   isShowIframePage.value = flag;
    // }

    // const test = ref(0);
    // function setTest(num) {
    //   test.value = num;
    // }

    // 体育投注成功随机值（用于更新体育账户）
    let sportsBetSuccess = ref(Math.random());
    function setSportsBetSuccess(val) {
      sportsBetSuccess.value = val;
    }

    // 邀请代理账户
    const inviteAccount = ref({});
    function setInviteAccount(val) {
      inviteAccount.value = val;
    }
    // 邀请代理账户是否有更新（增长）
    const inviteAccountChanged = ref(false);
    function setInviteAccountChanged(val) {
      inviteAccountChanged.value = val;
    }
    // 邀请代理账户变更推送消息
    const receiveInvitePushMsg = ref(false);
    function setReceiveInvitePushMsg(val) {
      receiveInvitePushMsg.value = val;
    }
    const showCoinsAnim = ref(false);
    function setShowCoinsAnim(val) {
      showCoinsAnim.value = val;
    }

    const isShowNewRouletteDia = ref(false);
    const newUserRouletteDiaData = ref({});
    const isJoinNewRoulette = ref(false); // 是否参与活动
    function setNewUserRouletteDia(flag, data) {
      isShowNewRouletteDia.value = flag;
      data && (newUserRouletteDiaData.value = data);
    }
    function setIsJoinNewRoulette(flag) {
      isJoinNewRoulette.value = flag;
    }

    // 尼日利亚国家的设置中英文切换：ng/ngzh
    const nowLang = ref('ng');
    function switchNowLang(lang) {
      nowLang.value = lang;
    }

    return {
      isLogin,
      switchLogin,
      appUpdateType,
      setAppUpdateType,
      checkSiteConfig,
      setCheckSiteConfig,
      checkSiteConfigRes,
      setCheckSiteConfigRes,
      baseUserInfo,
      setBaseUserInfo,
      setUserAccount,
      setUserMember,
      bannerList,
      setBannerList,
      setStationUnReadCount,
      operateUnReadCount,
      categoryData,
      setCategoryData,
      squareMarkShowObj,
      setSquareMarkShowObj,
      squareBigMarkShowObj,
      setSquareBigMarkShowObj,
      isShowChatIcon,
      switchShowChatIcon,
      include,
      setInclude,
      // isShowPickUpTip,
      // setIsShowPickUpTip,
      isShowPickUpDialog,
      showPickUpAmount,
      setIsShowPickUpDialog,
      useUserSocketCode,
      setUseUserSocket,
      useUserSocketContentCode,
      setUseUserSocketContentCode,
      isWLJActivty,
      wljObj,
      setWLJActivty,
      marqueeList,
      setMarqueeList,
      isShowRegisterUserInfoDialog,
      switchIsShowRegisterUserInfoDialog,
      isShowRegisterDialog,
      switchIsShowRegisterDialog,
      isShowAppLoginDialog,
      setIsShowAppLoginDialog,
      isShowSignInDialog,
      setIsShowSignInDialog,
      isShowActivityListDialog,
      setIsShowActivityListDialog,
      isShowNewUserTopUpDialog,
      switchIsShowNewUserTopUpDialog,
      indexDialogSysMap,
      setIndexDialogSysMap,
      indexDialogUserMap,
      setIndexDialogUserMap,
      isShowIndexDialogTotal,
      switchIsShowIndexDialogTotal,
      isShowFirstRechargeDialog,
      switchIsShowFirstRechargeDialog,
      isNeedGetUserInfo,
      setIsNeedGetUserInfo,
      serviceInfo,
      setServiceInfo,
      registerInfo,
      setRegisterInfo,
      selectTabbar,
      setSelectTabbar,
      tabFirstExpanded,
      setTabFirstExpanded,
      // isShowIframePage,
      // switchIsShowIframePage,
      // test,
      // setTest,
      testWatch,
      setTestWatch,
      setUserCoins,
      setExchange,
      setWallet,
      // setBotToken,
      isShowHookCoinsDia,
      setIsShowHookCoinsDia,
      hookCoinsData,
      setHookCoinsData,
      isShowRechargeDia,
      rechargeGoToPage,
      rechargeGoToPageCb,
      setIsShowRechargeDia,
      sportsBetSuccess,
      setSportsBetSuccess,
      isShowRecharge1Dia,
      setIsShowRecharge1Dia,
      inviteAccount,
      setInviteAccount,
      inviteAccountChanged,
      setInviteAccountChanged,
      receiveInvitePushMsg,
      setReceiveInvitePushMsg,
      showCoinsAnim,
      setShowCoinsAnim,
      isRecharged,
      setIsRecharged,
      newUserRouletteDiaData,
      isShowNewRouletteDia,
      setNewUserRouletteDia,
      isJoinNewRoulette,
      setIsJoinNewRoulette,
      nowLang,
      switchNowLang,
    };
  },
  {
    persist: {
      enabled: true,
      detached: true,
    },
  }
);
