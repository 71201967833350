import en from './en.json';
import zhHans from './zh-Hans.json';
import zhHant from './zh-Hant.json';
import ja from './ja.json';
import es from './es.json';
import co from './co.json';
import zh from './zh.json';
import br from './br.json';
import ng from './ng.json';
import ngzh from './ngzh.json';
import cl from './cl.json';
import pe from './pe.json';
import id from './id.json';
import ec from './ec.json';
import ph from './ph.json';

export default {
  en,
  ng,
  ngzh,
  zh,
  'zh-Hans': zhHans,
  'zh-Hant': zhHant,
  ja,
  es,
  co,
  br,
  cl,
  pe,
  id,
  ec,
  ph,
};
