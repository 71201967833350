import pagesJson from '../../pages.json'

import { OPERATING_TIME, STAT_URL, STAT_VERSION, DIFF_TIME } from './config'
import {
  dbGet,
  dbSet,
} from './db'
// 获取 manifest.json 中统计配置
const uniStatisticsConfig = process.env.UNI_STATISTICS_CONFIG
/**
 * 是否开启 debug 模式
 */
export const is_debug = false
const sys = uni.getSystemInfoSync();
let titleJsons = {}

let pagesData = pagesJson.pages
for (let i in pagesData) {
  const style: any = pagesData[i].style
  const titleText = style.navigationBarTitleText || ''
  if (titleText) {
    titleJsons[i] = titleText
  }
}
/**
 * 获取当前平台
 * 移动端  : 'n',
 * h5	  : 'h5',
 * 微信	  : 'wx',
 * 阿里	  : 'ali',
 * 百度	  : 'bd',
 * 头条	  : 'tt',
 * qq	  : 'qq',
 * 快应用  : 'qn',
 * 快手	  : 'ks',
 * 飞书	  : 'lark',
 * 快应用  : 'qw', 快应用通用(包含联盟、华为)
 * 钉钉	  : 'dt'
 */
export const get_platform_name = () => {
  // 苹果审核代码中禁止出现 alipay 字样 ，需要特殊处理一下
  const aliArr = ['y', 'a', 'p', 'mp-ali']
  const platformList = {
    app: 'n',
    'app-plus': 'n',
    h5: 'h5',
    web: 'h5',
    'mp-weixin': 'wx',
    [aliArr.reverse().join('')]: 'ali',
    'mp-baidu': 'bd',
    'mp-toutiao': 'tt',
    'mp-lark': 'lark',
    'mp-qq': 'qq',
    'mp-kuaishou': 'ks',
    'mp-jd': 'jd',
    'mp-360': '360',
    'quickapp-native': 'qn',
    'quickapp-webview': 'qw'
  }
  return platformList[sys.uniPlatform] || ''
}

export const get_page_name = (routepath) => {
  return (titleJsons && titleJsons[routepath]) || ''
}
/**
 * 获取小程序 appid
 */
export const get_pack_name = () => {
  let packName = ''
  if (get_platform_name() === 'wx' || get_platform_name() === 'qq') {
    // 兼容微信小程序低版本基础库
    if (uni.canIUse('getAccountInfoSync')) {
      packName = uni.getAccountInfoSync().miniProgram.appId || ''
    }
  }
  if (get_platform_name() === 'n') {
    // TODO APP 获取包名
  }
  return packName
}
/**
 * 获取渠道
 */
export const get_channel = () => {
  const platformName = get_platform_name()
  let channel: any = ''
  // #ifdef APP-PLUS
  if (platformName === 'n') {
    let isIosApp = uni.getSystemInfoSync().platform === 'ios'
    if (isIosApp) {
      channel = 'ios'
    } else {
      channel = plus.runtime.channel || ''
    }
  }
  // #endif
  if (platformName === 'wx') {
    channel = ''
    // TODO 需要调研小程序二维码渠道如何获取;
  }
  return channel
}
/**
 * 获取上报时间间隔
 * @param {*} defaultTime 默认上报间隔时间 单位s
 */
export const get_report_Interval = (defaultTime?: number) => {
  let time = OPERATING_TIME;
  // 如果上报时间配置为0 相当于立即上报
  if (Number(time) === 0) return 0
  time = defaultTime ? defaultTime : OPERATING_TIME
  let reg = /(^[1-9]\d*$)/;
  // 如果不是整数，则默认为上报间隔时间
  if (!reg.test(time + "")) return defaultTime
  return Number(time)
}

/**
 * 处理上报参数
 * @param {Object}  需要处理的数据
 */
export const handle_data = (statData) => {
  let firstArr: any = []
  let contentArr: any = []
  let lastArr: any = []
  for (let i in statData) {
    const rd = statData[i]
    rd.forEach((elm) => {
      let newData = ''
      // if (__STAT_VERSION__ === '1') {
      //   newData = get_splicing(elm)
      // }
      // if (__STAT_VERSION__ === '2') {
      //   newData = elm
      // }
      // if (i === 0) {
      //   firstArr.push(newData)
      // } else if (i === 3) {
      //   lastArr.push(newData)
      // } else {
      //   contentArr.push(newData)
      // }
      newData = elm
      if (+i === 0) {
        firstArr.push(newData)
      } else if (+i === 3) {
        lastArr.push(newData)
      } else {
        contentArr.push(newData)
      }
    })
  }

  firstArr.push(...contentArr, ...lastArr)
  // 参数需要处理成字符串，方便上传
  return JSON.stringify(firstArr)
}

/**
 * 获取页面url，不包含参数
 */
export const get_route = (pageVm?: any) => {
  let _self = pageVm || get_page_vm()
  // 未处理百度小程序兼容
  return (
    _self.route ||
    (_self.$scope && _self.$scope.route) ||
    (_self.$mp && _self.$mp.page.route)
  )
}

/**
 * 获取页面url, 包含参数
 */
export const get_page_route = (pageVm) => {
  // 从 app 进入应用 ，没有 $page ,获取不到路由 ，需要获取页面 尝试从 getCurrentPages 获取也页面实例
  // FIXME 尽量不使用 getCurrentPages ，大部分获取路由是从 onHide 获取 ，这时可以获取到，如果是 onload ,则可能获取不到，比如 百度

  let page = pageVm && (pageVm.$page || (pageVm.$scope && pageVm.$scope.$page))
  let lastPageRoute = uni.getStorageSync('_STAT_LAST_PAGE_ROUTE')
  if (!page) return lastPageRoute || ''
  // 如果找不到 fullPath 就取 route 的值
  return page.fullPath === '/' ? `/${page.route}` : page.fullPath || `/${page.route}`
}

/**
 * 获取页面实例
 */
export const get_page_vm = () => {
  let pages = getCurrentPages()
  if (pages.length) {
    let $page: any = pages[pages.length - 1]
    if (!$page) return null
    return $page?.$vm || null
  } else {
    return null
  }
}

/**
 * 获取小程序场景值
 * @param {Object} options 页面信息
 */
export const get_scene = (options) => {
  const platformName = get_platform_name()
  let scene: any = ''
  if (options) {
    return options
  }
  if (platformName === 'wx') {
    scene = uni.getLaunchOptionsSync().scene
  }
  return scene
}

const requestData = (done) => {
  const appid = sys.appId
  let formData = {
    usv: STAT_VERSION,
    conf: JSON.stringify({
      ak: appid,
    }),
  }
  uni.request({
    url: STAT_URL,
    method: 'GET',
    data: formData,
    success: (res: any) => {
      const { data } = res
      if (data.ret === 0) {
        typeof done === 'function' &&
          done({
            enable: data.enable,
          })
      }
    },
    fail: (e) => {
      let report_status_code: any = 1
      try {
        report_status_code = uni.getStorageSync(Report_Status)
      } catch (e) {
        report_status_code = 1
      }
      if (report_status_code === '') {
        report_status_code = 1
      }
      typeof done === 'function' &&
        done({
          enable: report_status_code,
        })
    },
  })
}

const Report_Data_Time = 'Report_Data_Time'
const Report_Status = 'Report_Status'
export const is_report_data = () => {
  return new Promise<void>((resolve, reject) => {
    let start_time: any = ''
    let end_time = new Date().getTime()
    let diff_time = DIFF_TIME
    let report_status: any = 1
    try {
      start_time = uni.getStorageSync(Report_Data_Time)
      report_status = uni.getStorageSync(Report_Status)
    } catch (e) {
      start_time = ''
      report_status = 1
    }

    if (report_status === '') {
      requestData(({ enable }) => {
        uni.setStorageSync(Report_Data_Time, end_time)
        uni.setStorageSync(Report_Status, enable)
        if (enable === 1) {
          resolve()
        }
      })
      return
    }

    if (report_status === 1) {
      resolve()
    }

    if (!start_time) {
      uni.setStorageSync(Report_Data_Time, end_time)
      start_time = end_time
    }

    if (end_time - start_time > diff_time) {
      requestData(({ enable }) => {
        uni.setStorageSync(Report_Data_Time, end_time)
        uni.setStorageSync(Report_Status, enable)
      })
    }
  })
}

/**
 * 获取页面类型
 */
export const get_page_types = (self) => {
  // XXX 百度有问题 ，获取的都是 componet ,等待修复
  if (
    self.mpType === 'page' ||
    self.$mpType === 'page' ||
    (self.$mp && self.$mp.mpType === 'page') ||
    self.$options.mpType === 'page'
  ) {
    return 'page'
  }
  if (
    self.mpType === 'app' ||
    self.$mpType === 'app' ||
    (self.$mp && self.$mp.mpType === 'app') ||
    self.$options.mpType === 'app'
  ) {
    return 'app'
  }
  return null
}

/**
 * 获取隐私协议配置
 */
export const is_push_clientid = () => {
  // if (uniStatisticsConfig.collectItems) {
  //   const ClientID = uniStatisticsConfig.collectItems.uniPushClientID
  //   return typeof ClientID === 'boolean' ? ClientID : false
  // }
  return false
}

/**
 * 是否已处理设备 DeviceId
 * 如果值为 1 则表示已处理
 */
const IS_HANDLE_DEVECE_ID = 'is_handle_device_id'
export const is_handle_device = () => {
  let isHandleDevice = dbGet(IS_HANDLE_DEVECE_ID) || ''
  dbSet(IS_HANDLE_DEVECE_ID, '1')
  return isHandleDevice === '1'
}

/**
 * 自定义事件参数校验
 */
export const calibration = (eventName, options) => {
  //  login 、 share 、pay_success 、pay_fail 、register 、title
  if (!eventName) {
    console.error(`uni.report Missing [eventName] parameter`)
    return true
  }
  if (typeof eventName !== 'string') {
    console.error(
      `uni.report [eventName] Parameter type error, it can only be of type String`
    )
    return true
  }
  if (eventName.length > 255) {
    console.error(
      `uni.report [eventName] Parameter length cannot be greater than 255`
    )
    return true
  }

  if (typeof options !== 'string' && typeof options !== 'object') {
    console.error(
      'uni.report [options] Parameter type error, Only supports String or Object type'
    )
    return true
  }

  if (typeof options === 'string' && options.length > 255) {
    console.error(
      `uni.report [options] Parameter length cannot be greater than 255`
    )
    return true
  }

  if (eventName === 'title' && typeof options !== 'string') {
    console.error(
      `uni.report [eventName] When the parameter is title, the [options] parameter can only be of type String`
    )
    return true
  }
}
/**
 * 日志输出
 * @param {*} data
 */
export const log = (data, type) => {
  let msg_type = ''
  switch (data.lt) {
    case '1':
      msg_type = '应用启动'
      break
    case '3':
      msg_type = '应用进入后台'
      break
    case '11':
      msg_type = '页面切换'
      break
    case '21':
      msg_type = '事件触发'
      break
    case '31':
      msg_type = '应用错误'
      break
    case '101':
      msg_type = 'PUSH'
      break
  }

  // #ifdef APP
  // 在 app 中，日志转为 字符串
  if (typeof data === 'object') {
    data = JSON.stringify(data)
  }
  // #endif

  if (type) {
    console.log(`=== 统计队列数据上报 ===`)
    console.log(data)
    console.log(`=== 上报结束 ===`)
    return
  }

  if (msg_type) {
    console.log(`=== 统计数据采集：${msg_type} ===`)
    console.log(data)
    console.log(`=== 采集结束 ===`)
  }
}
