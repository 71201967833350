import { $t } from '../scripts/language';

export const errorMsgEnum = {
  a: $t('validator.pwd.length'), // At least 8 digits;
  b: $t('validator.pwd.group'), // 大小写字母、数字、特殊字符至少包含两种
};

// 表单验证规则
export const valiRule = {
  require: { required: true, message: '' },
  notEmpty: {
    required: true,
    message: $t('validator.notEmpty'),
  },
  onlyNumber8: {
    required: true,
    pattern: /^\d{8}$/,
    message: $t('validator.idcardCL'),
  },
  onlyNumber: {
    required: true,
    pattern: /^\d{1,35}$/,
    message: $t('validator.onlyNumber'),
  },
  onlyString: {
    required: true,
    pattern: /^[a-zA-Z]{1,35}$/,
    message: $t('validator.onlyString'),
  },
  es_CO_idCard: {
    // 哥伦比亚身份证 - 输入字母和数字：30位
    required: true,
    pattern: /^[a-zA-Z\d]{1,30}$/,
    message: $t('validator.idcard'),
  },
  idCardCL: {
    // 输入字母和数字：30位
    required: true,
    pattern: /^[a-zA-Z\d]{1,30}$/,
    message: $t('validator.idcardCL'),
  },
  cciPE: {
    // 秘鲁cci - 输入字母和数字：20位
    required: true,
    pattern: /^[a-zA-Z\d]{1,20}$/,
    message: $t('validator.cciPE'),
  },
  bankNumberCL: {
    // 输入字母和数字：30位
    required: true,
    pattern: /^[a-zA-Z\d]{1,30}$/,
    message: $t('validator.bankNumberCL'),
  },
  bankNumberNG: {
    // 输入字母和数字：30位
    required: true,
    pattern: /^[a-zA-Z\d]{1,30}$/,
    message: $t('validator.bankNumberCL'),
  },
  pt_BR_idCard: {
    // 巴西cpf - 输入字母和数字：11位
    required: true,
    pattern: /\d{11}$/,
    message: $t('validator.idcard'),
  },
  id: {
    // CLABE码，16或18位
    required: true,
    pattern: /^(\d{16}|\d{18})$/,
    message: $t('validator.id'),
  },
  ine: {
    required: true,
    pattern: /\d{13}$/,
    message: $t('validator.ine'),
  },
  cic: {
    required: true,
    pattern: /\d{9}$/,
    message: $t('validator.cic'),
  },
  promoCode: {
    // 充值码
    required: false,
    pattern: /^[0-9a-zA-Z]{6,20}$/,
    message: $t('validator.promoCode'),
  },
  payAmount: [
    // 充值，后改为后端限制
    {
      required: true,
      type: 'number',
      min: 11,
      message: 'Min $11',
    },
    {
      required: true,
      type: 'number',
      max: 21000,
      message: 'Max $21000',
    },
    // {
    //   // TODO: 待删除，支持小数点的
    //   validator: function (rule, value, callback) {
    //     if (/.*\..*/.test(value)) {
    //       if (value.toString().split('.')[1].length > 5) {
    //         callback('小数点最多5位');
    //       } else {
    //         callback();
    //       }
    //     } else {
    //       callback();
    //     }
    //   },
    // },
  ],
  pickUpAmount: [
    // 提现，后改为后端限制
  ],
  smsCode: {
    required: true,
    pattern: /\d{6}$/,
    message: $t('validator.smsCode'), // 请填写6位验证码
  },
  smsCode2: [
    {
      required: true,
      message: $t('validator.smsCode2.require'), // 必须要有验证码
    },
    {
      pattern: /\d{6}$/,
      message: $t('validator.smsCode2.length'), // 请填写6位验证码
    },
  ],
  mobileDynamic: {
    required: true,
    pattern: new RegExp(`^\\d{${$t('common.phoneNumber')}}$`), // /^\d{$t('common.phoneNumber')}$/, // 国外手机号：“52”+10位
    message: $t('validator.mobile'), // 请填写完整的手机号！
  },
  mobileDynamicDis: {
    required: false,
    pattern: new RegExp(`^\\d{${$t('common.phoneNumber')}}$`), // /^\d{$t('common.phoneNumber')}$/, // 国外手机号：“52”+10位
    message: $t('validator.mobile'), // 请填写完整的手机号！
  },
  mobile: {
    required: true,
    pattern: /^\d{10}$/, // 国外手机号：“52”+10位
    message: $t('validator.mobile'), // 请填写完整的手机号！
  },
  mobileDis: {
    required: false,
    pattern: /^\d{10}$/, // 国外手机号：“52”+10位
    message: $t('validator.mobileDis'), // 请填写完整的手机号！
  },
  email: {
    required: true,
    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    message: $t('validator.email'), // 请填写正确的邮箱地址！
  },
  emailDis: {
    required: false,
    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    message: $t('validator.emailDis'), // 请填写正确的邮箱地址！
  },
  registerUsername: [
    {
      // /^(\+|[0-9a-zA-Z]{3,35})$/,
      validator(rule, value, callback, source, options) {
        let errors = [];
        // 验证手机号格式（假设手机号只包含数字，根据实际情况调整）
        // const phonePattern = /^\d{10}$/; // 假设合法的手机号码长度为10位数字
        // const usernamePattern = /^[0-9a-zA-Z]{3,35}$/; // 用户名必须由3-35位数字、字母组成
        const usernamePattern = /^(?=.*[a-zA-Z])[a-zA-Z\d]{3,35}$/; // 用户名必须由3-35位字母或者字母和数字组合（不允许全部是数字的情况）

        if (/^\+/.test(value)) {
          errors.push($t('validator.username.rule1')); // 用户在输入框，输入“+”时
        } else if (!usernamePattern.test(value)) {
          errors.push($t('validator.username.rule3')); // 请输入正确的登录信息
        }
        // 当符合第一个规则之后，才会进行后面的校验
        return errors;
      },
    },
  ],
  username: [
    {
      // /^(\+|[0-9a-zA-Z]{3,35})$/,
      validator(rule, value, callback, source, options) {
        let errors = [];
        // 验证手机号格式（假设手机号只包含数字，根据实际情况调整）
        const phonePattern = /^\d{10}$/; // 假设合法的手机号码长度为10位数字
        const usernamePattern = /^[0-9a-zA-Z]{3,35}$/; // 用户名必须由3-35位数字、字母组成

        if (/^\+/.test(value)) {
          errors.push($t('validator.username.rule1')); // 用户在输入框，输入“+”时
        } else if (!phonePattern.test(value)) {
          if (!usernamePattern.test(value)) {
            errors.push($t('validator.username.rule3')); // 请输入正确的登录信息
          }
        }
        // 当符合第一个规则之后，才会进行后面的校验
        return errors;
      },
    },
  ],
  realName: [
    {
      // /^(\+|[0-9a-zA-Z]{3,35})$/,
      validator(rule, value, callback, source, options) {
        let errors = [];
        // 验证手机号格式（假设手机号只包含数字，根据实际情况调整）
        const phonePattern = /^\d{10}$/; // 假设合法的手机号码长度为10位数字
        const usernamePattern = /^[0-9a-zA-Z\s]{3,35}$/; // 用户名必须由3-35位数字、字母空格组成

        if (/^\+/.test(value)) {
          errors.push($t('validator.username.rule1')); // 用户在输入框，输入“+”时
        } else if (!phonePattern.test(value)) {
          if (!usernamePattern.test(value)) {
            errors.push($t('validator.realName.rule3')); // 请输入正确的登录信息
          }
        }
        // 当符合第一个规则之后，才会进行后面的校验
        return errors;
      },
    },
  ],
  password: [
    {
      required: true,
      min: 8,
      max: 20,
      message: errorMsgEnum.a,
    },
    {
      pattern: /^(?![\d]+$)(?![a-z]+$)(?![A-Z]+$)[\da-zA-z]{2,}$/,
      message: errorMsgEnum.b,
    },
  ],
};
